import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select, Tabs, Upload, UploadFile } from 'antd';
import {
  CREATE_SUCCESS_MESSAGE,
  FIELD_REQUIRED,
  IMAGE_SIZE_LESS_5MB,
  INVALID_IMAGE_FORMAT,
  MAX_150_CHARACTER,
  MAX_50_CHARACTER,
  NO_EMOJI,
  NO_SPACE,
  NUMBER_INTEGER,
  UPDATE_SUCCESS_MESSAGE,
} from 'src/constants/message';
import { PATH } from 'src/configs/path';
import uploadFile from 'src/apis/service/uploadFile';
import config from 'src/serviceConfig.json';
import { CreateItemDto, Item, ItemReceiveMethodEnum, ItemTypeEnum } from 'src/graphql/type.interface';

import {
  MAX_IMAGE_SIZE,
  multiSpaceRegex,
  RECEIVE_METHOD_DISPLAY,
  regex,
  TYPE_ITEM_DISPLAY,
} from 'src/constants/constants';
import { useCreateItemMutation } from 'src/graphql/mutations/createItem.graphql';
import { useUpdateItemMutation } from 'src/graphql/mutations/updateItem.graphql';
import { number, object, string } from 'yup';
import { ColorPicker } from 'antd-5.20';
import { beforeUploadImage } from 'src/constants/function';

type TypeProps = { currentItem?: Item; isEdit?: boolean };

const yupSync = {
  async validator({ field }: any, value: any) {
    await object({
      name: string()
        .required(FIELD_REQUIRED)
        .max(50, MAX_50_CHARACTER)
        .test({ test: (value) => !value?.match(multiSpaceRegex), message: NO_SPACE })
        .matches(regex, NO_EMOJI),
      logo: object()
        .required(FIELD_REQUIRED)
        .test({
          test: (value: any) => {
            return value?.fileList?.length ? true : false;
          },
          message: FIELD_REQUIRED,
        })
        .test({
          test: (value: any) => (value?.file ? /.+\.(png|jpe?g|svg|gif)$/i.test(value?.file?.name) : true),
          message: INVALID_IMAGE_FORMAT,
        })
        .test({
          test: (value: any) => (value.file ? value?.file?.originFileObj?.size <= MAX_IMAGE_SIZE : true),
          message: IMAGE_SIZE_LESS_5MB,
        }),
      description: string()
        .required(FIELD_REQUIRED)
        .max(150, MAX_150_CHARACTER)
        .test({ test: (value) => !value?.match(multiSpaceRegex), message: NO_SPACE })
        .matches(regex, NO_EMOJI),
      point: number()
        .required(FIELD_REQUIRED)
        .integer(NUMBER_INTEGER)
        .min(1, 'Nhập số nguyên lớn hơn hoặc bằng 1')
        .integer('Không cho phép nhập số thập phân'),
      receiveMethod: string().required(FIELD_REQUIRED),
      type: string().required(FIELD_REQUIRED),
    }).validateAt(field, { [field]: value });
  },
};

export default ({ currentItem, isEdit }: TypeProps) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [arrayImage, setArrayImage] = useState<UploadFile[]>([]);
  const [urlThumbnail, setUrlThumbnail] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(false);
  const [receiveType, setReceiveType] = useState<any>();
  const [color, setColor] = useState<string>('#000000');
  const [typeColor, setTypeColor] = useState<string>('1');
  const [colorLinear, setColorLinear] = useState<string[]>([]);
  const [typeItem, setTypeItem] = useState<ItemTypeEnum>(ItemTypeEnum.AVATAR_BOUNDARY);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  form.setFieldsValue({ type: typeItem });

  const DEFAULT_COLOR = [
    {
      color: '#ffffff',
      percent: 0,
    },
    {
      color: '#000000',
      percent: 100,
    },
  ];

  const customRequestUpload = async (options: UploadRequestOption) => {
    const { file } = options;
    const formData = new FormData();

    formData.append('file', file);
    const res = await uploadFile(formData);

    if (res) {
      setUrlThumbnail(`${config.DOMAIN_CDN_IMAGE}/${res.data.path}`);
      setArrayImage([
        {
          uid: '1',
          name: res.data.path,
          url: `${config.DOMAIN_CDN_IMAGE}/${res.data.path}`,
          status: 'done',
        } as UploadFile,
      ]);
    }
  };

  useEffect(() => {
    if (currentItem) {
      const { name, isActivate, receiveMethod, type, description, logo, point, gradientCode } = currentItem;

      setIsActive(isActivate);
      receiveMethod && setReceiveType(receiveMethod);

      setTypeColor(logo && logo.slice(0, 4) === 'http' ? '3' : '2');

      type && setTypeItem(type as ItemTypeEnum);

      if (logo && logo.slice(0, 4) === 'http') {
        setArrayImage([
          {
            uid: '1',
            name: logo,
            url: logo,
            status: 'done',
          } as UploadFile,
        ]);
        form.setFieldsValue({
          logo: {
            fileList: [
              {
                uid: '1',
                name: logo,
                url: logo,
                status: 'done',
              },
            ],
          },
        });
      } else {
        setColor(`linear-gradient(135deg, ${gradientCode?.map((item) => item)})` as any);
        setColorLinear(gradientCode as any);
      }
      setUrlThumbnail(logo || '');

      form.setFieldsValue({
        name,
        isActivate,
        receiveMethod,
        type,
        description,
        point,
      });
    }
  }, [currentItem]);

  const [createItemMutation] = useCreateItemMutation({
    onCompleted() {
      enqueueSnackbar(CREATE_SUCCESS_MESSAGE, { variant: 'success' });
      setIsLoading(false);
      navigate(PATH.items.list);
    },
    onError({ message }) {
      setIsLoading(false);
    },
  });

  const [updateItemMutation] = useUpdateItemMutation({
    onCompleted() {
      enqueueSnackbar(UPDATE_SUCCESS_MESSAGE, { variant: 'success' });
      setIsLoading(false);
      navigate(PATH.items.list);
    },
    onError({ message }) {
      setIsLoading(false);
    },
  });

  const onFinish = async ({ description, name, point, receiveMethod, type }: CreateItemDto) => {
    setIsLoading(true);
    if (currentItem) {
      updateItemMutation({
        variables: {
          input: {
            _id: currentItem?._id,
            description,
            logo: urlThumbnail !== '' ? urlThumbnail : null,
            name,
            isActivate: isActive,
            receiveMethod,
            type,
            point: receiveMethod === ItemReceiveMethodEnum.STORE ? point : undefined,
            gradientCode: colorLinear,
          },
        },
      });
    } else {
      createItemMutation({
        variables: {
          input: {
            description,
            logo: urlThumbnail !== '' ? urlThumbnail : null,
            name,
            isActivate: isActive,
            receiveMethod,
            type,
            point: receiveMethod === ItemReceiveMethodEnum.STORE ? point : undefined,
            gradientCode: colorLinear,
          },
        },
      });
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Row>
      <Col offset={3} span={18}>
        <Form form={form} onFinish={!isLoading ? onFinish : () => {}}>
          <Form.Item
            label={
              <div>
                Loại vật phẩm <span style={{ color: 'red' }}>*</span>
              </div>
            }
            required={false}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="type"
            rules={[yupSync]}
          >
            <Select allowClear={false} onChange={(e) => setTypeItem(e)} disabled={isEdit}>
              {TYPE_ITEM_DISPLAY.map(({ value, key }) => (
                <Select.Option value={key}>{value}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          {typeItem === ItemTypeEnum.CHAT_BUBBLE ? (
            <Form.Item
              label={
                <div>
                  Mã màu <span style={{ color: 'red' }}>*</span>
                </div>
              }
              required={false}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="gradientCode"
              style={{ marginBottom: '0px' }}
              // rules={[yupSync]}
            >
              <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Tabs
                  defaultActiveKey={typeColor ?? '1'}
                  items={[
                    {
                      key: '1',
                      label: <img src="/image/Pickcolor.png" alt="Pick color" />,
                      children: (
                        <Form.Item name="pickColor">
                          <ColorPicker
                            defaultValue={'#000000'}
                            showText
                            allowClear
                            onChange={(e) => {
                              setColor(e.toHexString());
                              setColorLinear([e.toCssString(), e.toCssString()]);
                            }}
                          />
                        </Form.Item>
                      ),
                    },
                    {
                      key: '2',
                      label: <img src="/image/PickLinearColor.png" alt="Pick linear color" />,
                      children: (
                        <Form.Item name="pickLinearColor">
                          <ColorPicker
                            defaultValue={DEFAULT_COLOR}
                            allowClear
                            showText
                            mode={['gradient']}
                            onChange={(e) => {
                              const dataColor = e.getColors().map((item: any) => item.color.toCssString());
                              setColor(e.toCssString());
                              setColorLinear(dataColor);
                            }}
                          />
                        </Form.Item>
                      ),
                    },
                  ]}
                  onChange={(e) => {
                    setTypeColor(e);
                  }}
                />
              </Modal>
            </Form.Item>
          ) : typeItem === ItemTypeEnum.AVATAR_BOUNDARY && receiveType === ItemReceiveMethodEnum.DEFAULT ? (
            <></>
          ) : (
            <Form.Item
              label={
                <div>
                  Ảnh vật phẩm <span style={{ color: 'red' }}>*</span>
                </div>
              }
              required={false}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="logo"
              style={{ marginBottom: '0px' }}
              rules={[yupSync]}
            >
              <Upload
                accept="*/*"
                listType="picture-card"
                multiple={false}
                beforeUpload={beforeUploadImage}
                fileList={arrayImage}
                customRequest={customRequestUpload}
                onRemove={() => {
                  setArrayImage([]);
                  setUrlThumbnail('');
                }}
                disabled={isEdit}
              >
                <PlusOutlined rev={undefined} />
              </Upload>
            </Form.Item>
          )}

          {typeItem === ItemTypeEnum.CHAT_BUBBLE &&
            (color || arrayImage.length > 0 ? (
              <div onClick={isEdit ? () => {} : showModal} style={{ width: '100px', height: '100px' }}>
                <div
                  style={{
                    width: '100px',
                    height: '100px',
                    backgroundColor: `${color}`,
                    backgroundImage: `${color}`,
                    borderRadius: '20px',
                    textAlign: 'center',
                    color: 'white',
                    alignContent: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <p>Gamigo</p>
                  <PlusOutlined />
                </div>
              </div>
            ) : (
              <Button
                type="dashed"
                style={{
                  width: '100px',
                  height: '100px',
                }}
                onClick={showModal}
                disabled={isEdit}
              >
                <PlusOutlined />
              </Button>
            ))}

          <Form.Item
            label={
              <div>
                Tên vật phẩm <span style={{ color: 'red' }}>*</span>
              </div>
            }
            required={false}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="name"
            rules={[yupSync]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={
              <div>
                Mô tả <span style={{ color: 'red' }}>*</span>
              </div>
            }
            required={false}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="description"
            rules={[yupSync]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={
              <div>
                Cách nhận<span style={{ color: 'red' }}>*</span>
              </div>
            }
            required={false}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="receiveMethod"
            rules={[yupSync]}
          >
            <Select
              onChange={(e) => {
                setReceiveType(e);
                if (e === ItemReceiveMethodEnum.DEFAULT) {
                  setArrayImage([]);
                  setUrlThumbnail('');
                }
              }}
            >
              {RECEIVE_METHOD_DISPLAY.map(({ key, value }) => (
                <Select.Option key={key} value={key}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {receiveType === ItemReceiveMethodEnum.STORE && (
            <Form.Item
              label={
                <div>
                  Số point <span style={{ color: 'red' }}>*</span>
                </div>
              }
              required={false}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="point"
              rules={[yupSync]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          )}

          <Form.Item
            label={<div>Kích hoạt</div>}
            required={false}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="isActivate"
          >
            <Checkbox onClick={() => setIsActive(!isActive)} checked={isActive} />
          </Form.Item>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" htmlType="submit">
              Lưu
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};
