import { useState } from 'react';
import { Breadcrumb } from 'antd';
import { enqueueSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { PATH } from 'src/configs/path';
import Container from 'src/components/Container';
import servicePackage from 'src/apis/service/package';
import { GET_DETAIL_ERROR_MESSAGE } from 'src/constants/message';
import CommandSpecialForm from 'src/sections/package/CommandSpecialForm';

export default () => {
  const navigate = useNavigate();
  const id = useParams()._id;
  const [currentCode, setCurrentCode] = useState<any>();

  useQuery(['getPackageById', id], () => servicePackage.getSpecialCodeById(Number(id)), {
    retry: 0,
    refetchOnWindowFocus: false,
    onSuccess: ({ data, success }) => {
      if (success) {
        setCurrentCode(data);
      }
    },
  });

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(PATH.package.package.list)}>
                Gói cước
              </p>
            ),
          },
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(PATH.package.specialCodeCP.list)}>
                Cú pháp đặc biệt
              </p>
            ),
          },
          { title: 'Sửa' },
        ]}
      />
      <Container title="Cú pháp đặc biệt">
        <CommandSpecialForm currentCode={currentCode} />
      </Container>
    </>
  );
};
