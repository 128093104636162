import { TablePaginationConfig } from 'antd';
import type { FilterValue } from 'antd/es/table/interface';
import { BadRequestError } from 'src/constants/message';
export const getVideoDimensions = (url: string): Promise<{ height: number; width: number }> => {
  return new Promise((resolve) => {
    const video = document.createElement('video');

    video.addEventListener(
      'loadedmetadata',
      function () {
        resolve({ height: this.videoHeight, width: this.videoWidth });
      },
      false,
    );
    video.src = url;
  });
};

export const getImageDimensions = (url: string): Promise<{ height: number; width: number }> => {
  return new Promise((resolve) => {
    const image = document.createElement('img');

    image.addEventListener(
      'load',
      function () {
        resolve({ height: this.height, width: this.width });
      },
      false,
    );
    image.src = url;
  });
};

export interface TableParams {
  pagination: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

export let timeoutId: ReturnType<typeof setTimeout>;
export const debounce = <F extends (...args: any) => any>(func: F, time: number) => {
  return function (this: any, ...args: any) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, time);
  };
};

export const extractYouTubeVideoID = (url: string) => {
  const startIndex = url.indexOf('=') + 1;
  const endIndex = url.indexOf('&', startIndex);

  return endIndex !== -1 ? url.substring(startIndex, endIndex) : url.substring(startIndex);
};

export const messageReQuestError = (code: any) => {
  return BadRequestError.find((item) => Number(item.code) === code)?.message;
};
