import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useState, useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Card, Col, DatePicker, Form, Row, Select, Space, Table } from 'antd';
import Container from 'src/components/Container';
import { GET_LIST_ERROR_MESSAGE } from 'src/constants/message';
import {
  ContentProviderG1,
  PromotionCampaign,
  PromotionContentProviderReward,
  QueryPromotionContentProviderRewardDto,
} from 'src/graphql/type.interface';
import dayjs from 'dayjs';
import { FORMAT_DATE, FORMAT_TIME_TO_MINUTES } from 'src/constants/common';
import { RangeValue } from 'rc-picker/lib/interface';
import { usePromotionContentProviderRewardsQuery } from 'src/graphql/queries/promotionContentProviderRewards.graphql';
import { usePromotionCampaignsQuery } from 'src/graphql/queries/promotionCampaigns.graphql';
import { useAllContentProvidersQuery } from 'src/graphql/queries/allContentProviders.graphql';
import { PROMOTION_REWARD_TYPE } from 'src/constants/constants';
import { useDetailStore } from 'src/stores';

export default () => {
  const { userDetail } = useDetailStore();

  const navigate = useNavigate();
  const [items, setItems] = useState<PromotionContentProviderReward[]>([]);
  const [filter, setFilter] = useState<QueryPromotionContentProviderRewardDto>({
    limit: 10,
    page: 1,
    contentProviderId: userDetail?.contentProviderId ?? undefined,
  });
  const [totalItems, setTotalItems] = useState<number>(0);
  const [promotionData, setPromotionData] = useState<PromotionCampaign[]>([]);
  const [cpData, setCPData] = useState<ContentProviderG1[]>([]);

  const {
    refetch,
    data: dataPromotion,
    error,
  } = usePromotionContentProviderRewardsQuery({
    variables: { query: filter },
  });

  usePromotionCampaignsQuery({
    variables: { query: { limit: 10000, page: 1, contentProviderId: userDetail?.contentProviderId ?? undefined } },
    onCompleted(data) {
      setPromotionData(data.promotionCampaigns.items);
    },
  });

  useAllContentProvidersQuery({
    variables: { query: { limit: 10000, page: 1 } },
    onCompleted(data) {
      setCPData(data.allContentProviders.items);
    },
  });

  const pagination: TablePaginationConfig = {
    pageSize: filter.limit as number,
    current: filter.page as number,
    total: totalItems,
    onChange: (page: number, pageSize: number) => {
      setFilter({ ...filter, limit: pageSize, page });
    },
  };

  useEffect(() => {
    if (dataPromotion) {
      setItems(dataPromotion.promotionContentProviderRewards.items);
      setTotalItems(dataPromotion.promotionContentProviderRewards.meta.totalItems);
    }
    error && enqueueSnackbar(GET_LIST_ERROR_MESSAGE, { variant: 'error' });
  }, [dataPromotion, error]);

  useEffect(() => {
    refetch();
  }, [filter]);

  const handleSearchWithTime = (date: RangeValue<dayjs.Dayjs>) => {
    if (date) {
      setFilter({
        ...filter,
        page: 1,
        startTime: date ? dayjs(date[0]).startOf('day').toISOString() : '',
        endTime: date ? dayjs(date[1]).endOf('day').toISOString() : '',
      });
    } else {
      setFilter({
        ...filter,
        page: 1,
        startTime: undefined,
        endTime: undefined,
      });
    }
  };

  const columns: ColumnsType<PromotionContentProviderReward> = [
    {
      title: 'CP',
      dataIndex: 'cpName',
      key: 'cpName',
    },
    {
      title: 'CTKM',
      dataIndex: 'promotionName',
      key: 'promotionName',
    },
    {
      title: 'Thuê bao',
      dataIndex: 'isdn',
      key: 'isdn',
    },
    {
      title: 'Mã gói',
      dataIndex: 'packageCode',
      key: 'packageCode',
    },
    {
      title: 'Loại hình trả thưởng',
      dataIndex: 'rewardType',
      key: 'rewardType',
      render: (rewardType) => <>{PROMOTION_REWARD_TYPE.find((item) => item.key === rewardType)?.value}</>,
    },
    {
      title: 'Thời gian bắt đầu',
      dataIndex: 'rewardedTimeRange',
      key: 'rewardedTimeRange',
      render: (rewardedTimeRange) => <>{dayjs(rewardedTimeRange?.startTime).format(FORMAT_TIME_TO_MINUTES)}</>,
    },
    {
      title: 'Thời gian kết thúc',
      dataIndex: 'rewardedTimeRange',
      key: 'rewardedTimeRange',
      render: (rewardedTimeRange) => <>{dayjs(rewardedTimeRange?.endTime).format(FORMAT_TIME_TO_MINUTES)}</>,
    },
    {
      title: 'Mức trả thưởng',
      dataIndex: 'rewardValue',
      key: 'rewardValue',
    },
    // {
    //   title: 'Thời gian trả thưởng',
    //  0 dataIndex: 'rewardedTimeRange',
    //   key: 'rewardedTimeRange',
    //   width: 200,
    //   render: (arrDate) => (
    //     <>
    //       {dayjs(arrDate[0]).format(FORMAT_TIME_TO_MINUTES)} - {dayjs(arrDate[1]).format(FORMAT_TIME_TO_MINUTES)}
    //     </>
    //   ),
    // },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <>{status ? (status === 'SUCCESS' ? 'Thành công' : 'Thất bại') : ''}</>,
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => <>{dayjs(createdAt).format(FORMAT_TIME_TO_MINUTES)}</>,
    },
    {
      title: 'Nội dung trả thưởng',
      dataIndex: 'mtMessage',
      key: 'mtMessage',
    },
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(``)}>
                Cộng thưởng
              </p>
            ),
          },
          { title: 'Danh sách' },
        ]}
      />
      <Card size="small">
        <Row gutter={24}>
          {/* <Col span={6}>
            <Form.Item
              required={false}
              label={<span>Tìm kiếm</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: '100%' }}
            >
              <Input
                placeholder="Tìm kiếm theo username hoặc UID"
                onChange={(e) => {
                  if (e.target.value && e.target.value !== '') {
                    setFilter({ ...filter, page: 1, keyword: e.target.value });
                  } else {
                    setFilter({ ...filter, page: 1, keyword: undefined });
                  }
                }}
              />
            </Form.Item>
          </Col> */}
          <Col span={6}>
            <Form.Item
              required={false}
              label={<span>Tìm theo thời gian</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: '100%' }}
            >
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                format={[FORMAT_DATE]}
                onChange={(date: RangeValue<dayjs.Dayjs>) => handleSearchWithTime(date)}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              required={false}
              label={<span>CTKM</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: '100%' }}
            >
              <Select
                allowClear
                onChange={(e) => {
                  if (!userDetail?.contentProviderId) {
                    if (e) {
                      setFilter({ ...filter, page: 1, campaignId: e, contentProviderId: undefined });
                    } else {
                      setFilter({ ...filter, page: 1, campaignId: undefined, contentProviderId: undefined });
                    }
                  } else {
                    setFilter({ ...filter, page: 1, campaignId: e });
                  }
                }}
                placeholder="Tất cả"
              >
                {promotionData.map(({ _id, name }) => (
                  <Select.Option key={_id} value={_id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {!userDetail?.contentProviderId && (
            <Col span={4}>
              <Form.Item
                required={false}
                label={<span>CP</span>}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '100%' }}
              >
                <Select
                  allowClear
                  placeholder="Tất cả"
                  onChange={(e) => {
                    if (e) {
                      setFilter({ ...filter, page: 1, contentProviderId: e });
                    } else {
                      setFilter({ ...filter, page: 1, contentProviderId: undefined });
                    }
                  }}
                >
                  {cpData.map(({ _id, name }) => (
                    <Select.Option value={_id}>{name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Card>
      <Container title="Danh sách cộng thưởng">
        <Table columns={columns} dataSource={items} rowKey="_id" pagination={items?.length > 0 ? pagination : false} />
      </Container>
    </Space>
  );
};
