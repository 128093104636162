import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, message } from 'antd';
import { PATH, PATH_AUTH } from '../../configs/path';
import AuthRepository from '../../apis/service/administrator';
import { getUserId, setSession } from '../../utils/jwt';
import { useDetailStore, useLoginStore } from '../../stores/index';
import { FIELD_REQUIRED, LOGIN_SUCCESS_MESSAGE, RESET_PASSWORD_MESSAGE } from '../../constants/message';
import { enqueueSnackbar } from 'notistack';

type TypeForm = { type: string; username: string; password: string };

const Login = () => {
  const { setUserInfo } = useLoginStore();
  const { setUserDetail } = useDetailStore();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const getUserModule = async () => {
    const { data } = await AuthRepository.getAdminModule();
    if (data) {
      setUserInfo(data);
    }
  };

  const getUserDetail = async () => {
    const userId = getUserId();
    const { data } = await AuthRepository.getAdminDetail(userId);
    if (data) {
      setUserDetail(data);
    }
  };

  const onFinish = ({ username, password }: TypeForm) => {
    setIsLoading(true);
    AuthRepository.signIn({ type: 'Normal', username, password })

      .then((data) => {
        if (data.success) {
          if (data.data.isFirstLogin) {
            // setUserInfo(data);
            // setSession(data.data.accessToken);
            navigate(PATH_AUTH.resetPassword, { replace: true });
            setIsLoading(false);
            message.info(RESET_PASSWORD_MESSAGE);
            // getUserModule();
            // getUserDetail();
          } else {
            // setUserInfo(data);
            if (data.data.accessToken && !data.data.is2faPhone) {
              setSession(data.data.accessToken);
              navigate('/', { replace: true });
              message.success(LOGIN_SUCCESS_MESSAGE);
              setIsLoading(false);
              getUserModule();
              getUserDetail();
            } else {
              navigate(PATH.login2fa.root, { replace: true });
              setUserInfo({ username, password, phone: data.data.phone });
            }
          }
        } else {
          message.error(data.message);
          setIsLoading(false);
        }
      })
      .catch((data) => {
        message.error(data.response.data.message), setIsLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Đăng nhập</title>
      </Helmet>
      <div style={styleWrapper}>
        <div style={styleLogo}>
          <img style={{ width: '50%' }} src="/image/logo.svg" alt="" />
        </div>
        <Card style={styleCard}>
          <Form initialValues={{ isRemember: false, type: 'Normal' }} onFinish={onFinish} autoComplete="off">
            {/* <Form.Item name="type" rules={[{ required: true, message: FIELD_REQUIRED }]}>
              <Select placeholder="Phương thức đăng nhập" options={options} size="large" />
            </Form.Item> */}
            <Form.Item name="username" rules={[{ required: true, message: FIELD_REQUIRED }]}>
              <Input placeholder="Tài khoản" prefix={<UserOutlined rev={undefined} />} size="large" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: FIELD_REQUIRED }]}>
              <Input.Password placeholder="Mật khẩu" prefix={<LockOutlined rev={undefined} />} size="large" />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button type="primary" htmlType="submit" size="large" style={{ width: '100%' }} disabled={isLoading}>
                Đăng nhập
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default Login;

const styleWrapper: React.CSSProperties = {
  width: 500,
  position: 'fixed',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const styleLogo: React.CSSProperties = {
  marginBottom: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const styleCard: React.CSSProperties = {
  boxShadow: '0 4px 16px 0 rgba(167, 175, 183, 0.33)',
};
