import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { Breadcrumb, Button, Divider, Tag } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH } from 'src/configs/path';
import Container from 'src/components/Container';
import { GET_DETAIL_ERROR_MESSAGE } from 'src/constants/message';
import dayjs from 'dayjs';
import { FORMAT_TIME_TO_MINUTES } from 'src/constants/common';
import { Payment } from 'src/types/payment';
import paymentService from 'src/apis/service/payment';
import { getBillingChannelName, paymentTransactionStatus } from 'src/constants/payment';

export default () => {
  const navigate = useNavigate();
  const [data, setData] = useState<Payment>();
  const transactionId = useParams().transactionId as string;

  useQuery(
    ['getPaymentByTransactionId', transactionId],
    () => paymentService.getReportPaymentByTransactionId(transactionId),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: ({ data, success }) => {
        if (success) {
          setData(data);
        } else {
          enqueueSnackbar(GET_DETAIL_ERROR_MESSAGE, { variant: 'error' });
        }
      },
    },
  );

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(PATH.package.package.list)}>
                Chi tiết lịch sử đăng ký gói cước
              </p>
            ),
          },
          { title: `Transaction ID : ${data?.transactionId}` },
        ]}
      />
      <Container title="Chi tiết lịch sử đăng ký gói cước">
        {data && (
          <div style={{ padding: '0 10%' }}>
            <Divider style={{ marginTop: 8, marginBottom: 8 }} plain></Divider>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%' }}>ID</span>
              <span style={{ flex: 1 }}>{data.id}</span>
            </div>

            <Divider style={{ marginTop: 8, marginBottom: 8 }} plain></Divider>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%' }}>Transaction ID</span>
              <span style={{ flex: 1 }}>{data.transactionId}</span>
            </div>

            <Divider style={{ marginTop: 8, marginBottom: 8 }} plain></Divider>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%' }}>UID</span>
              <span style={{ flex: 1 }}>{data.transactionBy}</span>
            </div>

            <Divider style={{ marginTop: 8, marginBottom: 8 }} plain></Divider>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%' }}>Thuê bao</span>
              <span style={{ flex: 1 }}>{data.isdn}</span>
            </div>

            <Divider style={{ marginTop: 8, marginBottom: 8 }} plain></Divider>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%' }}>Tên tài khoản</span>
              <span style={{ flex: 1 }}>{data.username}</span>
            </div>

            <Divider style={{ marginTop: 8, marginBottom: 8 }} plain></Divider>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%' }}>Gói cước</span>
              <span style={{ flex: 1 }}>{data.packageCode}</span>
            </div>

            <Divider style={{ marginTop: 8, marginBottom: 8 }} plain></Divider>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%' }}>Giá gói</span>
              <span style={{ flex: 1 }}>{data.packagePrice}</span>
            </div>

            <Divider style={{ marginTop: 8, marginBottom: 8 }} plain></Divider>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%' }}>Giá trị thanh toán</span>
              <span style={{ flex: 1 }}>{data.billingAmount}</span>
            </div>

            <Divider style={{ marginTop: 8, marginBottom: 8 }} plain></Divider>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%' }}>Ngày giao dịch</span>
              <span style={{ flex: 1 }}>{dayjs(data.createdAt).format(FORMAT_TIME_TO_MINUTES)}</span>
            </div>

            <Divider style={{ marginTop: 8, marginBottom: 8 }} plain></Divider>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%' }}>Phương thức thanh toán</span>
              <span style={{ flex: 1 }}>{getBillingChannelName(data.billingChannel)}</span>
            </div>

            <Divider style={{ marginTop: 8, marginBottom: 8 }} plain></Divider>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%' }}>Trạng thái</span>
              <span style={{ flex: 1 }}>
                {
                  <Tag color={paymentTransactionStatus[data.status].color}>
                    {paymentTransactionStatus[data.status].text}
                  </Tag>
                }
              </span>
            </div>

            <>
              <Divider style={{ marginTop: 8, marginBottom: 8 }} plain></Divider>
            </>

            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
              <Button type="primary" onClick={() => navigate(PATH.package.paymentHistory.list)}>
                Quay lại
              </Button>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};
