import { callApi } from 'src/apis/connection/callApi';

export default {
  getHistoryCDRMM(query: any): Promise<any> {
    return callApi({ method: 'get', uri: '/payment/v1/report/cdr-mm', query });
  },

  exportExcelHistoryCDRMM(query: any): Promise<any> {
    return callApi({ method: 'get', uri: '/payment/v1/report/export/cdr-mobifone-money', query, responseType: 'blob' });
  },
};
