import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useState, useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Card, Col, DatePicker, Form, Input, Row, Select, Space, Table } from 'antd';
import Container from 'src/components/Container';
import { GET_LIST_ERROR_MESSAGE } from 'src/constants/message';
import dayjs from 'dayjs';
import { FORMAT_TIME_TO_MINUTES } from 'src/constants/common';
import { RangeValue } from 'rc-picker/lib/interface';
import AchievementResponsive, { HistoryReceivedPayload, TypeReceived } from 'src/apis/service/achievement';
import { useQuery } from '@tanstack/react-query';
import { TYPE_REWARD_ACHIEVEMENT } from 'src/constants/constants';
import { Milestone } from 'src/graphql/type.interface';
import { useMilestonesQuery } from 'src/graphql/queries/milestones.graphql';
import { messageReQuestError } from 'src/utils/common';

export default () => {
  const [filter, setFilter] = useState<HistoryReceivedPayload>({
    limit: 10,
    page: 1,
    type: TypeReceived.ACHIEVEMENT,
  });

  const [dataHistory, setDataHistory] = useState<any>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [mileStoneData, setMileStoneData] = useState<Milestone[]>([]);

  const { refetch } = useQuery(
    ['getHistoryReceivedAchievement'],
    () => AchievementResponsive.getHistoryReceived(filter),
    {
      enabled: true,
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          setDataHistory(data.data.items);
          setTotalItems(data.data.meta.totalItems);
        }
      },
    },
  );

  useMilestonesQuery({
    variables: { query: { limit: 10000, page: 1 } },
    onCompleted(data) {
      setMileStoneData(data.milestones.items);
    },
  });

  const pagination: TablePaginationConfig = {
    pageSize: filter.limit as number,
    current: filter.page as number,
    total: totalItems,
    onChange: (page: number, pageSize: number) => {
      setFilter({ ...filter, limit: pageSize, page });
    },
  };

  useEffect(() => {
    refetch();
  }, [filter]);

  const handleSearchWithTime = (date: RangeValue<dayjs.Dayjs>) => {
    if (date) {
      setFilter({
        ...filter,
        page: 1,
        startTime: date ? dayjs(date[0]).startOf('day').toISOString() : '',
        endTime: date ? dayjs(date[1]).endOf('day').toISOString() : '',
      });
    } else {
      setFilter({
        ...filter,
        page: 1,
        startTime: undefined,
        endTime: undefined,
      });
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Tên tài khoản',
      dataIndex: 'username',
      key: 'username',
    },

    {
      title: 'UID',
      dataIndex: 'userRefId',
      key: 'userRefId',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Tên thành tựu',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mốc ',
      dataIndex: 'levelMilestone',
      key: 'levelMilestone',
    },
    {
      title: 'Loại phần thưởng',
      dataIndex: 'rewardType',
      key: 'rewardType',
    },
    {
      title: 'Phần thưởng',
      dataIndex: 'rewardValue',
      key: 'rewardValue',
    },
    {
      title: 'Ngày nhận thưởng',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => <>{dayjs(date).format(FORMAT_TIME_TO_MINUTES)}</>,
    },
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Card size="small">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              required={false}
              label={<span>Tìm kiếm</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: '100%' }}
            >
              <Input
                placeholder="Tìm kiếm theo username hoặc UID"
                onChange={(e) => {
                  if (e.target.value && e.target.value !== '') {
                    setFilter({ ...filter, page: 1, keyword: e.target.value });
                  } else {
                    setFilter({ ...filter, page: 1, keyword: undefined });
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              required={false}
              label={<span>Tìm theo thời gian</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: '100%' }}
            >
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                onChange={(date: RangeValue<dayjs.Dayjs>) => handleSearchWithTime(date)}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              required={false}
              label={<span>Loại phần thưởng</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: '100%' }}
            >
              <Select
                allowClear
                onChange={(e) => {
                  if (e) {
                    setFilter({ ...filter, page: 1, rewardType: e });
                  } else {
                    setFilter({ ...filter, page: 1, rewardType: undefined });
                  }
                }}
                placeholder="Tất cả"
              >
                {TYPE_REWARD_ACHIEVEMENT.map(({ key, value }) => (
                  <Select.Option key={key} value={key}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              required={false}
              label={<span>Mốc</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: '100%' }}
            >
              <Select
                allowClear
                placeholder="Tất cả"
                onChange={(e) => {
                  if (e) {
                    setFilter({ ...filter, page: 1, level: e });
                  } else {
                    setFilter({ ...filter, page: 1, level: undefined });
                  }
                }}
              >
                {mileStoneData.map((item) => (
                  <Select.Option value={item.level}>{item?.level}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Container title="Lịch sử nhận thưởng thành tựu">
        <Table
          columns={columns}
          dataSource={dataHistory}
          rowKey="_id"
          pagination={dataHistory?.length > 0 ? pagination : false}
        />
      </Container>
    </Space>
  );
};
