import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Input, InputNumber, Radio, Row, Select } from 'antd';
import {
  CREATE_SUCCESS_MESSAGE,
  FIELD_REQUIRED,
  MAX_255_CHARACTER,
  UPDATE_SUCCESS_MESSAGE,
} from 'src/constants/message';
import { PATH } from 'src/configs/path';
import {
  AchievementCustom,
  AchievementResourceTypeEnum,
  AchievementRewardTypeEnum,
  AchievementTypeEnum,
  CreateAchievementDto,
} from 'src/graphql/type.interface';
import { TYPE_ACHIEVEMENT_DISPLAY, TYPE_REWARD_ACHIEVEMENT_DISPLAY } from 'src/constants/constants';
import { object, string } from 'yup';
import { useCreateAchievementMutation } from 'src/graphql/mutations/createAchievement.graphql';
import { useUpdateAchievementMutation } from 'src/graphql/mutations/updateAchievement.graphql';

type Props = { achievement?: AchievementCustom; isDetail?: boolean };

const yupSync = {
  async validator({ field }: any, value: any) {
    await object({
      type: string().required(FIELD_REQUIRED),
      name: string().max(255, MAX_255_CHARACTER).required(FIELD_REQUIRED),
      description: string().max(255, MAX_255_CHARACTER).required(FIELD_REQUIRED),
      performs: string().required(FIELD_REQUIRED),
      quantity: string().required(FIELD_REQUIRED),
      typeQuantity: string().required(FIELD_REQUIRED),
      typeReward: string().required(FIELD_REQUIRED),
      value: string().required(FIELD_REQUIRED),
    }).validateAt(field, { [field]: value });
  },
};

export default ({ achievement, isDetail }: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [typeAchiement, setTypeAchiement] = useState<AchievementTypeEnum>();
  const [awardAchievement, setAwardAchievement] = useState<AchievementRewardTypeEnum>(AchievementRewardTypeEnum.ENERGY);
  const [quantity, setQuantity] = useState<number>(0);
  const [typeQuantity, setTypeQuantity] = useState<AchievementResourceTypeEnum>(AchievementResourceTypeEnum.GPOINT);
  const [valueReward, setValueReward] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  form.setFieldsValue({ typeReward: awardAchievement });

  const [createAchievementMutation] = useCreateAchievementMutation({
    onCompleted() {
      setIsLoading(false);
      enqueueSnackbar(CREATE_SUCCESS_MESSAGE, { variant: 'success' });
      navigate(PATH.achievement.list);
    },
    onError({ message }) {
      setIsLoading(false);
    },
  });

  const [updateAchievementMutation] = useUpdateAchievementMutation({
    onCompleted() {
      setIsLoading(false);
      enqueueSnackbar(UPDATE_SUCCESS_MESSAGE, { variant: 'success' });
      navigate(PATH.achievement.list);
    },
    onError({ message }) {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (achievement) {
      const { name, reward, description, completionQuantity, performs, type } = achievement;
      setAwardAchievement(reward?.type);
      setQuantity(completionQuantity?.quantity as number);
      setTypeQuantity(completionQuantity?.type as AchievementResourceTypeEnum);
      setValueReward(reward?.value);
      setTypeAchiement(type);

      form.setFieldsValue({
        type,
        name,
        description,
        performs,
        quantity: completionQuantity?.quantity,
        typeQuantity: completionQuantity?.type,
        typeReward: reward?.type,
        value: reward?.value,
      });
    }
  }, [achievement]);

  const onFinish = async ({ name, type, description, performs }: CreateAchievementDto) => {
    setIsLoading(true);
    if (achievement) {
      updateAchievementMutation({
        variables: {
          input: {
            _id: achievement?._id,
            reward: {
              type: awardAchievement,
              value: valueReward.toString(),
            },
            type,
            description,
            completionQuantity: quantity && typeQuantity ? { quantity: quantity, type: typeQuantity } : undefined,
            performs,
          },
        },
      });
    } else {
      createAchievementMutation({
        variables: {
          input: {
            name: name,
            reward: {
              type: awardAchievement,
              value: valueReward.toString(),
            },
            type,
            description,
            completionQuantity: quantity && typeQuantity ? { quantity: quantity, type: typeQuantity } : undefined,
            performs,
          },
        },
      });
    }
  };
  const handleChangeType = (e: any) => {
    setTypeAchiement(e);
    form.setFieldsValue({ performs: undefined });
    switch (e) {
      case AchievementTypeEnum.FIRST_DEPOSIT:
        return form.setFieldsValue({ performs: 1 });
      case AchievementTypeEnum.ACCUMULATE_STAR:
        setTypeQuantity(AchievementResourceTypeEnum.STAR);
        return form.setFieldsValue({ typeQuantity: AchievementResourceTypeEnum.STAR }); //Star
      case AchievementTypeEnum.ACCUMULATE_GPOINT:
        setTypeQuantity(AchievementResourceTypeEnum.GPOINT);
        return form.setFieldsValue({ typeQuantity: AchievementResourceTypeEnum.GPOINT });
      case AchievementTypeEnum.ACCUMULATE_ENERGY:
        setTypeQuantity(AchievementResourceTypeEnum.ENERGY);
        return form.setFieldsValue({ typeQuantity: AchievementResourceTypeEnum.ENERGY });
      case AchievementTypeEnum.CONSUMPTION_GPOINT:
        setTypeQuantity(AchievementResourceTypeEnum.GPOINT);
        return form.setFieldsValue({ typeQuantity: AchievementResourceTypeEnum.GPOINT });
      case AchievementTypeEnum.PLAY_GAME:
        return form.setFieldsValue({ performs: 1, typeQuantity: AchievementResourceTypeEnum.STAR });
    }
  };

  return (
    <Row>
      <Col offset={3} span={18}>
        <Form form={form} onFinish={isLoading ? () => {} : onFinish}>
          <Form.Item
            label={
              <div>
                Loại thành tựu <span style={{ color: 'red' }}>*</span>
              </div>
            }
            required={false}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="type"
            rules={[yupSync]}
          >
            <Select onChange={(e) => handleChangeType(e)}>
              {TYPE_ACHIEVEMENT_DISPLAY.map(({ value, key }) => (
                <Select.Option value={key}>{value}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={
              <div>
                Tên thành tựu <span style={{ color: 'red' }}>*</span>
              </div>
            }
            required={false}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="name"
            rules={[yupSync]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={
              <div>
                Mô tả <span style={{ color: 'red' }}>*</span>
              </div>
            }
            required={false}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="description"
            rules={[yupSync]}
          >
            <Input />
          </Form.Item>

          <div>
            <h3>Điều kiến hoàn thành </h3>

            {typeAchiement !== AchievementTypeEnum.ACCUMULATE_ENERGY &&
              typeAchiement !== AchievementTypeEnum.ACCUMULATE_GPOINT &&
              typeAchiement !== AchievementTypeEnum.ACCUMULATE_STAR &&
              typeAchiement !== AchievementTypeEnum.CONSUMPTION_GPOINT && (
                <Form.Item
                  label={
                    <div>
                      Số lần hoàn thành<span style={{ color: 'red' }}>*</span>
                    </div>
                  }
                  required={false}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name="performs"
                  rules={[yupSync]}
                >
                  <InputNumber
                    disabled={
                      typeAchiement === AchievementTypeEnum.FIRST_DEPOSIT ||
                      typeAchiement === AchievementTypeEnum.PLAY_GAME
                    }
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              )}

            {/* completionQuantity */}
            {(typeAchiement === AchievementTypeEnum.ACCUMULATE_ENERGY ||
              typeAchiement === AchievementTypeEnum.ACCUMULATE_GPOINT ||
              typeAchiement === AchievementTypeEnum.ACCUMULATE_STAR ||
              typeAchiement === AchievementTypeEnum.CONSUMPTION_GPOINT ||
              typeAchiement === AchievementTypeEnum.PLAY_GAME) && (
              <Row gutter={24}>
                <Col span={20}>
                  <Form.Item
                    label={
                      <div>
                        Số lượng hoàn thành<span style={{ color: 'red' }}>*</span>
                      </div>
                    }
                    required={false}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="quantity"
                    rules={[yupSync]}
                  >
                    <InputNumber style={{ width: '100%' }} onChange={(e) => setQuantity(e as number)} />
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item
                    label={<div></div>}
                    required={false}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="typeQuantity"
                    rules={[yupSync]}
                  >
                    <Select
                      onChange={(e) => setTypeQuantity(e)}
                      disabled={
                        typeAchiement === AchievementTypeEnum.ACCUMULATE_STAR ||
                        typeAchiement === AchievementTypeEnum.ACCUMULATE_GPOINT ||
                        typeAchiement === AchievementTypeEnum.ACCUMULATE_ENERGY ||
                        typeAchiement === AchievementTypeEnum.CONSUMPTION_GPOINT ||
                        typeAchiement === AchievementTypeEnum.PLAY_GAME
                      }
                    >
                      {TYPE_REWARD_ACHIEVEMENT_DISPLAY.map(({ key, value }) => (
                        <Select.Option value={key}>{value}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}

            {/* reward */}
            <Form.Item
              label={
                <div>
                  Phần thưởng<span style={{ color: 'red' }}>*</span>
                </div>
              }
              required={false}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="typeReward"
              rules={[yupSync]}
            >
              <Radio.Group
                onChange={(e) => {
                  setAwardAchievement(e.target.value);
                  form.setFieldsValue({ value: '' });
                }}
                defaultValue={awardAchievement}
                disabled={isDetail}
              >
                <Radio value={AchievementResourceTypeEnum.ENERGY}>Energy</Radio>
                <Radio value={AchievementResourceTypeEnum.GPOINT}>GPoint</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              required={false}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="value"
              rules={[yupSync]}
            >
              <InputNumber onChange={(e) => setValueReward(e as string)} style={{ width: '100%' }} />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" htmlType="submit" disabled={isLoading}>
              Lưu
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};
