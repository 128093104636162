import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useState, useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Card, Col, Form, Row, Select, Space, Table } from 'antd';
import Container from 'src/components/Container';
import { GET_LIST_ERROR_MESSAGE } from 'src/constants/message';
import { ContentProviderG1, PromotionCampaign } from 'src/graphql/type.interface';
import { usePromotionCampaignsQuery } from 'src/graphql/queries/promotionCampaigns.graphql';
import { useAllContentProvidersQuery } from 'src/graphql/queries/allContentProviders.graphql';
import { useQuery } from '@tanstack/react-query';
import PromotionRespon from 'src/apis/service/promotionArtifactCodes';
import { useDetailStore } from 'src/stores';

export default () => {
  const { userDetail } = useDetailStore();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [tableData, setTableData] = useState<any[]>([]);
  const [filter, setFilter] = useState<any>({
    limit: 10,
    page: 1,
    contentProviderId: userDetail?.contentProviderId ?? undefined,
  });
  const [totalItems, setTotalItems] = useState<number>(0);
  const [promotionData, setPromotionData] = useState<PromotionCampaign[]>([]);
  const [cpData, setCPData] = useState<ContentProviderG1[]>([]);
  const [isloading, setIsloading] = useState<boolean>(true);

  const { refetch } = useQuery(
    ['getEffectivenessPromotionReport'],
    () => PromotionRespon.getEffectivenessPromotionReport(filter),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: ({ data, total }) => {
        setTableData(data);
        setTotalItems(total);
        setIsloading(false);
      },
    },
  );

  usePromotionCampaignsQuery({
    variables: { query: { limit: 10000, page: 1, contentProviderId: userDetail?.contentProviderId ?? undefined } },
    onCompleted(data) {
      setPromotionData(data.promotionCampaigns.items);
    },
  });

  useAllContentProvidersQuery({
    variables: { query: { limit: 10000, page: 1 } },
    onCompleted(data) {
      setCPData(data.allContentProviders.items);
    },
  });

  const pagination: TablePaginationConfig = {
    pageSize: filter.limit as number,
    current: filter.page as number,
    total: totalItems,
    onChange: (page: number, pageSize: number) => {
      setFilter({ ...filter, limit: pageSize, page });
    },
  };

  useEffect(() => {
    setIsloading(true);
    refetch();
  }, [filter]);

  const handleArrCp = () => {
    const arrCP = promotionData
      .find((item) => item?._id === filter?.campaignId)
      ?.promotionStrategies.filter(
        (item, index, self) => index === self.findIndex((t) => t.contentProviderId === item.contentProviderId),
      );

    return arrCP;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'CP',
      dataIndex: 'cpName',
      key: 'cpName',
    },
    {
      title: 'Mã gói',
      dataIndex: 'packageCode',
      key: 'packageCode',
    },
    {
      title: 'CTKM',
      dataIndex: 'campaignName',
      key: 'campaignName',
    },
    {
      title: 'Thuê bao tham gia',
      dataIndex: 'subJoinPromotion',
      key: 'subJoinPromotion',
    },
    {
      title: 'Thuê bao phát triển mới',
      dataIndex: 'subFirstTimeRegister',
      key: 'subFirstTimeRegister',
    },
    {
      title: 'Thuê bao được cộng thưởng',
      dataIndex: 'isdnReceivedReward',
      key: 'isdnReceivedReward',
    },

    {
      title: 'Thuê bao trúng thưởng',
      dataIndex: 'isdnEligibleReward',
      key: 'isdnEligibleReward',
    },
    {
      title: 'Thuê bao hủy',
      dataIndex: 'subCancel',
      key: 'subCancel',
    },
    {
      title: 'Doanh thu',
      dataIndex: 'revenuePackage',
      key: 'revenuePackage',
    },
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(``)}>
                Hiệu quả CTKM
              </p>
            ),
          },
          { title: 'Danh sách' },
        ]}
      />
      <Card size="small">
        <Row gutter={24}>
          <Col span={4}>
            <Form.Item
              required={false}
              label={<span>CTKM</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: '100%' }}
            >
              <Select
                allowClear
                onChange={(e) => {
                  if (e) {
                    setFilter({ ...filter, page: 1, campaignId: e, contentProviderId: undefined });
                    form.setFieldsValue({ cp: undefined });
                  } else {
                    setFilter({ ...filter, page: 1, campaignId: undefined, contentProvider: undefined });
                    form.setFieldsValue({ cp: undefined });
                  }
                }}
                placeholder="Tất cả"
              >
                {promotionData.map(({ _id, name }) => (
                  <Select.Option key={_id} value={_id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form form={form}>
              <Form.Item
                required={false}
                label={<span>CP</span>}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '100%' }}
                name="cp"
              >
                <Select
                  allowClear
                  placeholder="Tất cả"
                  onChange={(e) => {
                    if (e) {
                      setFilter({ ...filter, page: 1, contentProviderId: e });
                    } else {
                      setFilter({ ...filter, page: 1, contentProviderId: undefined });
                    }
                  }}
                  disabled={!filter?.campaignId}
                >
                  {(handleArrCp() ?? []).map(({ contentProviderId }) => (
                    <Select.Option value={contentProviderId}>
                      {cpData.find((item) => item._id === contentProviderId)?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
      <Container title="Báo cáo hiệu quả CTKM">
        <Table
          columns={columns}
          dataSource={tableData}
          rowKey="_id"
          pagination={tableData?.length > 0 ? pagination : false}
          loading={isloading}
        />
      </Container>
    </Space>
  );
};
