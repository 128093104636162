export const FIELD_REQUIRED = 'Trường bắt buộc';
export const MIN_8_CHARACTER = 'Tối thiểu 8 ký tự';
export const MIN_5_CHARACTER = 'Tối thiểu 5 ký tự';
export const MAX_300_CHARACTER = 'Tối đa 300 ký tự';
export const MAX_25_CHARACTER = 'Tối đa 25 ký tự';
export const MAX_30_CHARACTER = 'Tối đa 30 ký tự';
export const MAX_50_CHARACTER = 'Tối đa 50 ký tự';
export const MAX_150_CHARACTER = 'Tối đa 150 ký tự';
export const MAX_200_CHARACTER = 'Tối đa 200 ký tự';
export const MAX_255_CHARACTER = 'Tối đa 255 ký tự';
export const MAX_500_CHARACTER = 'Tối đa 500 ký tự';
export const MAX_1000_CHARACTER = 'Tối đa 1000 ký tự';
export const NO_SPECIAL_CHARACTER_NO_EMOJI = 'Không có ký tự đặc biệt và không có emoji';
export const NO_SPECIAL_CHARACTER = 'Không có ký tự đặc biệt';
export const NO_EMOJI = 'Không có emoji';
export const UPLOAD_FAILURE_MESSAGE = 'Lỗi tải lên';
export const URL_YOUTUBE = 'Đường dẫn youtube không đúng';
export const URL_YOUTUBE_AND_INTERNAL_LINK = 'Đường dẫn không đúng';
export const NUMBER_INTEGER = 'Nhập số nguyên';
export const NO_SPACE = 'Không có nhiều khoảng trắng';
export const IMAGE_SIZE_LESS_5MB = 'Ảnh không thể vượt quá 5MB';
export const INVALID_IMAGE_FORMAT = 'Định dạng ảnh không hợp lệ';
export const INVALID_EXCEL_FORMAT = 'Định dạng file không hợp lệ';
export const NO_SPECIAL_CHARACTER_MINUS_DASH = 'Không có khoảng trắng và ký tự đặc biệt';

//AUTH MESSAGE
export const LOGIN_SUCCESS_MESSAGE = 'Đăng nhập thành công';
export const LOGIN_FAILURE_MESSAGE = 'Đăng nhập không thành công!';
export const LOGOUT_SUCCESS_MESSAGE = 'Đã đăng xuất!';
export const REGISTER_SUCCESS_MESSAGE = 'Đăng ký tài khoản thành công. Vui lòng đăng nhập!';
export const RESET_PASSWORD_MESSAGE = 'Vui lòng đổi mật khẩu sau lần đầu tiên đăng nhập';
export const CHANGE_PASSWORD_SUCCESS_MESSAGE = 'Đổi mật khẩu thành công';
export const TOKEN_EXPIRED_MESSAGE = 'Phiên làm việc đã hết hạn. Vui lòng đăng nhập lại';

//API MESSAGE
export const GET_DETAIL_ERROR_MESSAGE = 'Không thể lấy thông tin chi tiết!';
export const GET_LIST_ERROR_MESSAGE = 'Không thể lấy thông tin danh sách!';
export const EXPORT_EXCEL_ERROR = 'Tải xuống file excel lỗi';
export const DELETE_ERROR_MESSAGE = 'Không thể xóa!';
export const DELETE_SUCCESS_MESSAGE = 'Xóa thành công!';
export const CREATE_SUCCESS_MESSAGE = 'Thêm thành công!';
export const CREATE_ERROR_MESSAGE = 'Thêm thất bại!';
export const UPDATE_SUCCESS_MESSAGE = 'Cập nhật thông tin thành công!';
export const UPDATE_ERROR_MESSAGE = 'Cập nhật thông tin thất bại!';
export const APPROVE_SUCCESS_MESSAGE = 'Duyệt thành công!';
export const APPROVE_ERROR_MESSAGE = 'Duyệt thất bại!';
export const CLONE_SUCCESS_MESSAGE = 'Sao chép thông tin gói cước thành công';

//VALIDATE FORM
export const INVALID_SEQUENCE_NUMBER_MESSAGE = 'Số thứ tự không hợp lệ';

export const NO_SPACES_MESSAGE = 'Không được chứa khoảng trắng trong tên người dùng';
export const USERNAME_FORM_MESSAGE =
  'Tài khoản không dấu, không khoảng trắng, không kí tự đặc biệt, không dấu chấm phẩy, không emoji tối thiểu 5 kí tự, tối đa 25 kí tự!';
export const REALNAME_FORM_MESSAGE =
  'Chỉ nhập các chữ cái, không ký tự đặc biệt, số, dấu chấm phẩy, không có 2 khoảng trắng liền nhau và độ dài từ 6 đến 50 ký tự';
export const EMAIL_FORM_MESSAGE = 'Đia chỉ email không hợp lệ';
export const PASSWORD_FORM_MESSAGE =
  'Mật khẩu có ít nhất một chữ hoa, một chữ thường, một chữ số và một ký tự đặc biệt, không khoảng trắng, không emoji và độ dài từ 8 đến 40 ký tự';
export const PASSWORD_MESSAGE_TOO_LONG = 'Mật khẩu không vượt quá 40 kí tự';
export const PASSWORD_MESSAGE_TOO_SHORT = 'Mật khẩu nhiều hơn 8 kí tự';
export const CONFIRM_PASSWORD_FORM_MESSAGE = 'Xác nhận mật khẩu không khớp';
export const UPLOAD_IMAGE_MESSAGE = 'Vui lòng tải lên hình ảnh ';

export const ROLE_REQUIRED_MESSAGE = 'Quyền cho nhóm là bắt buộc!';
export const READ_PERMISSION_REQUIRED_MESSAGE = 'Quyền xem là bắt buộc!';

export const NUMBER_DIVISIBLE_10 = 'Nhập số chia hết cho 10';
export const NUMBER_GREATER_THAN_OR_EQUAL_TO_0 = 'Số phải lớn hơn hoặc bằng 0';
export const NUMBER_GREATER_THAN_OR_EQUAL_TO_1 = 'Số phải lớn hơn hoặc bằng 1';
export const NUMBER_LESS_THAN_OR_EQUAL_TO_100 = 'Số phải nhỏ hơn hoặc bằng 100';

export const VIDEO_TYPE_VALIDATION = 'Chỉ chấp nhận video/mp4 file';
export const IMAGE_TYPE_VALIDATION = 'Vui lòng upload image tại đây';
export const NO_MULTIPLE_UPLOAD = 'Upload nhiều file không được hỗ trợ';
export const MAX_5 = 'Số nhỏ hơn hoặc bằng 5';

export const BadRequestError = [
  { code: '4000001', message: 'Quảng cáo nhận thưởng không tồn tại!' },
  { code: '4000002', message: 'Quảng cáo không tồn tại!' },
  { code: '4000003', message: 'Không có banner!' },
  { code: '4000004', message: 'Loại trận đấu không hợp lệ!' },
  { code: '4000005', message: 'Bạn không thể cập nhật trận đấu này!' },
  { code: '4000006', message: 'Trận đấu không tồn tại!' },
  {
    code: '4000007',
    message: 'Không thể cập nhật lại người tham gia trận đấu đang diễn ra!',
  },
  { code: '4000008', message: 'Danh mục không tồn tại!' },
  { code: '4000009', message: 'Event tourament không tồn tại!' },
  { code: '4000010', message: 'Loại phản hồi không hợp lệ!' },
  { code: '4000011', message: 'Vui lòng nhập địa chỉ email!' },
  { code: '4000012', message: 'Không có ID được cung cấp cho phản hồi!' },
  { code: '4000013', message: 'Không tìm thấy phản hồi!' },
  { code: '4000014', message: 'Game không tồn tại!' },
  { code: '4000015', message: 'Bạn không có quyền thao tác!' },
  { code: '4000016', message: 'Bạn không thể chỉnh sửa vật phẩm này thành mặc định!' },
  { code: '4000017', message: 'Bạn chưa like game!' },
  { code: '4000018', message: 'Không có nhiệm vụ!' },
  { code: '4000019', message: 'Intro không tồn tại!' },
  {
    code: '4000020',
    message: 'Không thể cập nhật chương trình khuyến mãi đang diễn ra!',
  },
  {
    code: '4000021',
    message: 'Không thể cập nhật chương trình khuyến mãi đã kết thúc!',
  },
  {
    code: '4000022',
    message: 'Không thể xóa chương trình khuyến mãi đang diễn ra!',
  },
  {
    code: '4000023',
    message: 'Không thể xóa chương trình khuyến mãi đã kết thúc!',
  },
  {
    code: '4000024',
    message: 'Không thể cập nhật mã khuyến mãi của chương trình đang diễn ra!',
  },
  {
    code: '4000025',
    message: 'Không thể cập nhật mã khuyến mãi của chương trình đã kết thúc!',
  },
  { code: '4000026', message: 'Không thể cập nhật mã khuyến mãi đã trúng thưởng!' },
  {
    code: '4000027',
    message: 'Số lượng mã trúng thưởng vượt qua số lượng giải thưởng!',
  },
  {
    code: '4000028',
    message: 'Không thể xóa chương trình khuyến mãi đang diễn ra!',
  },
  {
    code: '4000029',
    message: 'Không thể xóa chương trình khuyến mãi đã kết thúc!',
  },
  {
    code: '4000030',
    message: 'Không thể tạo mới cấu hình của chương trình khuyến mãi đang diễn ra!',
  },
  {
    code: '4000031',
    message: 'Không thể tạo mới cấu hình của chương trình khuyến mãi đã kết thúc!',
  },
  {
    code: '4000032',
    message: 'Không thể cập nhật cấu hình của chương trình khuyến mãi đang diễn ra!',
  },
  {
    code: '4000033',
    message: 'Không thể cập nhật cấu hình của chương trình khuyến mãi đã kết thúc!',
  },
  {
    code: '4000034',
    message: 'Không thể xóa cấu hình của chương trình khuyến mãi đã kết thúc!',
  },
  {
    code: '4000035',
    message: 'Không thể xóa cấu hình của chương trình khuyến mãi đang diễn ra!',
  },
  {
    code: '4000036',
    message: 'Không thể xóa quà tặng của chương trình khuyến mãi đang diễn ra!',
  },
  {
    code: '4000037',
    message: 'Không thể xóa quà tặng của chương trình khuyến mãi dã kết thúc!',
  },
  {
    code: '4000038',
    message: 'Không thể tạo mới quà tặng của chương trình khuyến mãi đang diễn ra!',
  },
  {
    code: '4000039',
    message: 'Không thể tạo mới quà tặng của chương trình khuyến mãi đã kết thúc!',
  },
  {
    code: '4000040',
    message: 'Không thể cập nhật quà tặng của chương trình khuyến mãi đang diễn ra!',
  },
  {
    code: '4000041',
    message: 'Không thể cập nhật quà tặng của chương trình khuyến mãi đã kết thúc!',
  },
  { code: '4000042', message: 'Tỉ lệ chuyển đổi không tồn tại!' },
  { code: '4000043', message: 'Tên thẻ không hợp lệ!' },
  { code: '4000044', message: 'Team không tồn tại!' },
  { code: '4000045', message: 'Tour không tồn tại!' },
  { code: '4000046', message: 'Không thể tham gia phòng đấu vì số lượng team đăng ký đã đủ!' },
  { code: '4000047', message: 'Team đã được thêm vào, không thể tạo mới!' },
  { code: '4000048', message: 'Trận đấu chưa hoàn thành khởi tạo, vui lòng đợi 5s rồi thử lại!' },
  { code: '4000049', message: 'Video không tồn tại!' },
  { code: '4000050', message: 'Chương trình khuyến mãi không tồn tại!' },
  { code: '4010001', message: 'Token không hợp lệ!' },
  { code: '4010002', message: 'Tài khoản không hợp lệ!' },
  { code: '4010003', message: 'Phiên đăng nhập đã hết hạn!' },
  { code: '4010004', message: 'Bạn không có quyền để import danh sách trả thưởng!' },
  { code: '4090001', message: 'Tên đã tồn tại!' },
  { code: '4090002', message: 'Đã có thành tựu trong mốc khác!' },
  {
    code: '4090003',
    message: 'Thành tựu này đã được gắn trong mốc khác. Không thể chỉnh sửa!',
  },
  { code: '4090004', message: 'Không thể trùng loại thành tựu trong mốc!' },
  { code: '4090005', message: 'Tiêu đề đã tồn tại!' },
  { code: '4090006', message: 'Tên chương trình đã tồn tại!' },
  { code: '4090007', message: 'Gói cước bạn chọn đã bị trùng!' },
  { code: '4090008', message: 'Mã quảng cáo đã tồn tại!' },
  { code: '4090009', message: 'Mã khuyến mãi đã tồn tại!' },
  { code: '4090010', message: 'Tên giải thưởng đã tồn tại!' },
  { code: '4090011', message: 'Type đã tồn tại!' },
  { code: '4090012', message: 'Nhiệm vụ đã tồn tại!' },
  { code: '4090013', message: 'Quảng cáo nhận thưởng đã tồn tại!' },
  { code: '4090014', message: 'Trận đấu của giải đấu đã tồn tại!' },
  { code: '4090015', message: 'Vật phẩm đã tồn tại!' },
  { code: '4090016', message: 'Vật phẩm mặc định đã tồn tại!' },
  { code: '4090017', message: 'Tên quà tặng đã tồn tại!' },
  { code: '4090018', message: 'Mã đối tác đã tồn tại!' },
  { code: '4090019', message: 'Tên công ty đã tồn tại!' },
  { code: '4090020', message: 'Sub command bạn chọn đã trùng với công ty khu vực khác' },
  { code: '4090021', message: 'Tên tiêu đề đã tồn tại!' },
  { code: '4090022', message: 'Slider đã tồn tại!' },
  { code: '4090023', message: 'Tên nhãn đã tồn tại!' },
  { code: '4090024', message: 'Nhãn đã tồn tại!' },
  { code: '4090025', message: 'Team đã tồn tại!' },
  { code: '4090026', message: 'Tên team đã tồn tại!' },
  { code: '4090027', message: 'Có thành viên bị trùng với đội khác!' },
  { code: '4090028', message: 'Tên giải đấu đã tồn tại!' },
  { code: '4090029', message: 'Tên video đã tồn tại!' },
  { code: '4090030', message: 'Tên thể loại đã tồn tại!' },
  { code: '4090031', message: 'Tên sự kiện đã tồn tại!' },
  { code: '4090032', message: 'Tên game đã tồn tại!' },
  { code: '4000051', message: 'Có lỗi xảy ra khi yêu thích game!' },
];
