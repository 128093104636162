import { useQuery } from '@tanstack/react-query';
import { Col, Divider, Form, Input, InputNumber, Radio, Row, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FIELD_REQUIRED, GET_LIST_ERROR_MESSAGE, NO_EMOJI, NO_SPACE, NUMBER_INTEGER } from 'src/constants/message';
import dataPackageRepository from 'src/apis/service/package';
import { ContentProviderG1, PromotionStrategy, TypeRewardEnum, TypeStrategyEnum } from 'src/graphql/type.interface';
import { array, number, object, string } from 'yup';
import {
  multiSpaceRegex,
  regex,
  TIME_REWARD_PROMOTION,
  TYPE_REWARD_PROMOTION,
  TYPE_STRATEGY,
} from 'src/constants/constants';
import { useAllContentProvidersQuery } from 'src/graphql/queries/allContentProviders.graphql';

type Props = {
  data?: PromotionStrategy;
  onInputChange: (tab: any, field: any, value: any, isEdit: boolean) => void;
  tabKey?: string;
  isEdit: boolean;
  dataPackage?: string[];
  dataContentProvider?: string[];
  form?: any;
  isDetail?: boolean;
};

export default ({ data, onInputChange, tabKey, isEdit, dataPackage, form, dataContentProvider, isDetail }: Props) => {
  const [packages, setPackages] = useState<any[]>([]);
  const [packageCode, setPackageCode] = useState<string>('');
  const [typeStrategy, setTypeStrategy] = useState<string>('');
  const [dataCp, setDataCp] = useState<ContentProviderG1[]>([]);
  const [typeReward, setTypeReward] = useState<string>('');
  const [currentCP, setCurrentCP] = useState<string>('');

  const yupSync = {
    async validator({ field }: any, value: any) {
      await object({
        [`typeStrategy_${tabKey}`]: string().required(FIELD_REQUIRED),
        [`contentProviderId_${tabKey}`]: string().required(FIELD_REQUIRED),
        [`packageCode_${tabKey}`]: string().required(FIELD_REQUIRED),
        [`commandCode_${tabKey}`]: array().test({
          test: (value) => (value?.length as number) > 0,
          message: FIELD_REQUIRED,
        }),
        [`type_${tabKey}`]: string().required(FIELD_REQUIRED),
        [`countConsecutiveRenewal_${tabKey}`]: number()
          .required(FIELD_REQUIRED)
          .integer(NUMBER_INTEGER)
          .min(1, 'Nhập số nguyên lớn hơn hoặc bằng 1'),
        [`addReward_${tabKey}`]: string().required(FIELD_REQUIRED),
        [`value_${tabKey}`]: number()
          .required(FIELD_REQUIRED)
          .integer(NUMBER_INTEGER)
          .min(1, 'Nhập số nguyên lớn hơn hoặc bằng 1'),
        [`rewardTimes_${tabKey}`]: number().required(FIELD_REQUIRED),
        [`mtRegisterRenew_${tabKey}`]: string()
          .test({ test: (value) => !value?.match(multiSpaceRegex), message: NO_SPACE })
          .test({
            test: (value) => {
              if (value) {
                return regex.test(value);
              }
              if (value === '') {
                return true;
              }
              return true;
            },
            message: NO_EMOJI,
          })
          .nullable(),
        [`mtReward_${tabKey}`]: string()
          .test({ test: (value) => !value?.match(multiSpaceRegex), message: NO_SPACE })
          .test({
            test: (value) => {
              if (value) {
                return regex.test(value);
              }
              if (value === '') {
                return true;
              }
              return true;
            },
            message: NO_EMOJI,
          })
          .nullable(),
      }).validateAt(field, { [field]: value });
    },
  };

  const { refetch } = useQuery(
    ['getPackages'],
    () =>
      dataPackageRepository.getPackages({
        limit: 1000,
        page: 1,
        contentProviderId: currentCP !== '' ? currentCP : undefined,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,

      onSuccess: ({ data, success }) => {
        if (success) {
          setPackages(data.items);
        }
      },
    },
  );

  useEffect(() => {
    refetch();
  }, [currentCP]);

  useAllContentProvidersQuery({
    variables: { query: { limit: 1000, page: 1, isActivate: true } },
    onCompleted(data) {
      setDataCp(data.allContentProviders.items);
    },
  });

  useEffect(() => {
    if (data) {
      const { messageMT, reward, packageCode, typeStrategy, contentProviderId, countConsecutiveRenewal, commandCode } =
        data;
      packageCode && setPackageCode(packageCode);

      reward && setTypeReward(reward.type as string);

      typeStrategy && setTypeStrategy(typeStrategy as string);

      contentProviderId && setCurrentCP(contentProviderId);

      form.setFieldsValue({
        [`typeStrategy_${tabKey}`]: typeStrategy,
        [`contentProviderId_${tabKey}`]: contentProviderId,
        [`packageCode_${tabKey}`]: packageCode,
        [`commandCode_${tabKey}`]: commandCode,
        [`type_${tabKey}`]: reward?.type,
        [`addReward_${tabKey}`]: reward?.addReward,
        [`countConsecutiveRenewal_${tabKey}`]: countConsecutiveRenewal,
        [`value_${tabKey}`]: reward?.value,
        [`rewardTimes_${tabKey}`]: reward?.rewardTimes,
        [`mtRegisterRenew_${tabKey}`]: messageMT?.mtRegisterRenew,
        [`mtReward_${tabKey}`]: messageMT?.mtReward,
      });
    }
  }, [data, form]);

  const handleChangeTypeStrategy = (e: any) => {
    onInputChange(tabKey, 'typeStrategy', e.target.value, isEdit);
    setTypeStrategy(e.target.value);
    form.setFieldsValue({
      [`commandCode_${tabKey}`]: [],
      [`countConsecutiveRenewal_${tabKey}`]: undefined,
    });
  };

  const handlePickPackage = (e: any) => {
    onInputChange(tabKey, 'packageCode', e, isEdit);

    if (typeStrategy === TypeStrategyEnum.RENEWAL) {
      onInputChange(
        tabKey,
        'commandCode',
        packages.find((item) => item?.code === e)?.allCommands?.map((item: any) => item),
        isEdit,
      );
    }
    setPackageCode(e);
    form.setFieldsValue({ [`commandCode_${tabKey}`]: [] });
  };

  const handleChangeTypeReward = (e: any) => {
    onInputChange(tabKey, 'type', e.target.value, isEdit);
    setTypeReward(e.target.value);
    form.setFieldsValue({
      [`value_${tabKey}`]: undefined,
    });
  };

  return (
    <div
      style={{
        border: '1px solid #1677ff',
        borderTop: 'none',
        padding: '2%',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
      }}
    >
      <p style={{ fontSize: '25px' }}>Điều kiện</p>

      <Form.Item
        label={
          <span>
            Hình thức<span style={{ color: 'red' }}>*</span>
          </span>
        }
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name={`typeStrategy_${tabKey}`}
        rules={[yupSync]}
      >
        <Radio.Group onChange={(e) => handleChangeTypeStrategy(e)} disabled={isDetail}>
          {TYPE_STRATEGY.map(({ key, value }) => (
            <Radio value={key}>{value}</Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label={
          <span>
            Chọn CP<span style={{ color: 'red' }}>*</span>
          </span>
        }
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name={`contentProviderId_${tabKey}`}
        rules={[yupSync]}
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Chọn nhà cung cấp"
          style={{ width: '100%' }}
          onChange={(e) => {
            onInputChange(tabKey, 'contentProviderId', e, isEdit), setCurrentCP(e);
            form.setFieldsValue({ [`packageCode_${tabKey}`]: undefined });
          }}
          disabled={isDetail}
        >
          {dataCp.map(({ _id, name }) => (
            <Select.Option key={_id} value={_id} disabled={dataContentProvider?.includes(name as string)}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {currentCP !== '' && (
        <Form.Item
          label={
            <span>
              Gói cước<span style={{ color: 'red' }}>*</span>
            </span>
          }
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name={`packageCode_${tabKey}`}
          rules={[yupSync]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            placeholder="Chọn gói cước"
            style={{ width: '350px' }}
            onChange={(e) => handlePickPackage(e)}
            disabled={isDetail}
          >
            {packages.map(({ _id, code }) => (
              <Select.Option key={_id} value={code} disabled={dataPackage?.includes(code)}>
                {code}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {packages.find((item) => item?.code === packageCode) && typeStrategy !== TypeStrategyEnum.RENEWAL && (
        <Form.Item
          label={
            <span>
              Cú pháp<span style={{ color: 'red' }}>*</span>
            </span>
          }
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name={`commandCode_${tabKey}`}
          rules={typeStrategy === TypeStrategyEnum.RENEWAL ? [] : [yupSync]}
        >
          <Select
            showSearch
            mode="multiple"
            optionFilterProp="children"
            placeholder="Chọn cú pháp"
            style={{ width: '350px' }}
            onChange={(e) => onInputChange(tabKey, 'commandCode', e, isEdit)}
            disabled={typeStrategy === TypeStrategyEnum.RENEWAL || isDetail}
          >
            {packages
              .find((item) => item?.code === packageCode)
              ?.allCommands?.map((item: string) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        label={
          <span>
            Số lần gia hạn liên tiếp <span style={{ color: 'red' }}>*</span>
          </span>
        }
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name={`countConsecutiveRenewal_${tabKey}`}
        rules={typeStrategy === TypeStrategyEnum.REGISTER ? [] : [yupSync]}
      >
        <InputNumber
          placeholder="Nhập số lần gia hạn"
          onChange={(e) => onInputChange(tabKey, 'countConsecutiveRenewal', e, isEdit)}
          disabled={typeStrategy === TypeStrategyEnum.REGISTER || isDetail}
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Divider style={{ marginTop: 8, marginBottom: 8 }} plain></Divider>

      <p style={{ fontSize: '25px' }}>Phần thưởng</p>

      <Form.Item
        label={
          <span>
            Loại phần thưởng<span style={{ color: 'red' }}>*</span>
          </span>
        }
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name={`type_${tabKey}`}
        rules={[yupSync]}
      >
        <Radio.Group onChange={(e) => handleChangeTypeReward(e)} disabled={isDetail}>
          {TYPE_REWARD_PROMOTION.map(({ key, value }) => (
            <Radio value={key}>{value}</Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Row gutter={24}>
        <Col span={typeReward === TypeRewardEnum.DATA ? 20 : 24}>
          <Form.Item
            label={
              <span>
                Giá trị cộng thưởng<span style={{ color: 'red' }}>*</span>
              </span>
            }
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name={`value_${tabKey}`}
            rules={[yupSync]}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Nhập giá trị cộng thưởng"
              onChange={(e) => onInputChange(tabKey, 'value', e, isEdit)}
              disabled={isDetail}
            />
          </Form.Item>
        </Col>

        {typeReward === TypeRewardEnum.DATA && (
          <Col span={4}>
            {' '}
            <Form.Item label={<span></span>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Input defaultValue={'GB'} disabled={true} />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Form.Item
        label={
          <span>
            Số lần cộng thưởng CTKM <span style={{ color: 'red' }}>*</span>
          </span>
        }
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name={`addReward_${tabKey}`}
        required={false}
        rules={[{ required: true, message: FIELD_REQUIRED }]}
      >
        <Radio.Group onChange={(e) => onInputChange(tabKey, 'addReward', e.target.value, isEdit)} disabled={isDetail}>
          {TIME_REWARD_PROMOTION.map(({ key, value }) => (
            <Radio value={key}>{value}</Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Divider style={{ marginTop: 8, marginBottom: 8 }} plain></Divider>

      <p style={{ fontSize: '25px' }}>Thông báo</p>

      <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="messageMT">
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={<span>MT đăng ký/ Gia hạn</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name={`mtRegisterRenew_${tabKey}`}
              rules={[yupSync]}
            >
              <TextArea
                style={{ height: '150px' }}
                onChange={(e) => onInputChange(tabKey, 'mtRegisterRenew', e.target.value, isEdit)}
                disabled={isDetail}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={<span>MT cộng tiền thưởng</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name={`mtReward_${tabKey}`}
              rules={[yupSync]}
            >
              <TextArea
                style={{ height: '150px' }}
                onChange={(e) => onInputChange(tabKey, 'mtReward', e.target.value, isEdit)}
                disabled={isDetail}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
};
