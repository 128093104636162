import { useState } from 'react';
import { Breadcrumb, Button } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH } from 'src/configs/path';
import Container from 'src/components/Container';
import { Item } from 'src/graphql/type.interface';
import dayjs from 'dayjs';
import { FORMAT_DATE } from 'src/constants/common';
import { useItemQuery } from 'src/graphql/queries/item.graphql';
import { RECEIVE_METHOD_DISPLAY, TYPE_ITEM_DISPLAY } from 'src/constants/constants';

export default () => {
  const _id = useParams()._id as string;

  const [item, setItem] = useState<Item>();
  const navigate = useNavigate();
  useItemQuery({
    variables: { id: _id },
    onCompleted(data) {
      setItem(data.item);
    },
  });
  return (
    <>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(PATH.items.list)}>
                Chi tiết vật phẩm
              </p>
            ),
          },
          { title: `Chi tiết: ${item?.name}` },
        ]}
      />
      <Container title="Chi tiết vật phẩm">
        {item && (
          <div style={{ padding: '0 10%' }}>
            <div style={{ marginTop: '16px', display: 'flex' }}>
              <span style={{ width: '25%', marginBottom: '2%' }}>Loại vật phẩm</span>
              <span style={{ flex: 1 }}>{TYPE_ITEM_DISPLAY.find((i) => i.key === item.type)?.value}</span>
            </div>

            {item?.logo && (
              <div style={{ display: 'flex', marginBottom: '2%' }}>
                <span style={{ width: '25%', marginBottom: '2%' }}>Ảnh vật phẩm </span>
                <span style={{ flex: 1 }}>
                  <img src={item?.logo as string} alt={item?.name} style={{ width: '150px', height: '150px' }} />
                </span>
              </div>
            )}

            {(item?.gradientCode ?? []).length > 0 && (
              <div style={{ display: 'flex', marginBottom: '2%' }}>
                <span style={{ width: '25%', marginBottom: '2%' }}>Màu </span>
                <span style={{ flex: 1 }}>
                  <div
                    style={{
                      width: '150px',
                      height: '150px',
                      backgroundImage: `linear-gradient(135deg,${item?.gradientCode})`,
                      borderRadius: '20px',
                    }}
                  ></div>
                </span>
              </div>
            )}

            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%', marginBottom: '2%' }}>Tên vật phẩm </span>
              <span style={{ flex: 1 }}>{item?.name}</span>
            </div>

            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%', marginBottom: '2%' }}>Mô tả </span>
              <span style={{ flex: 1 }}>{item?.description}</span>
            </div>

            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%', marginBottom: '2%' }}>Cách nhận </span>
              <span style={{ flex: 1 }}>{RECEIVE_METHOD_DISPLAY.find((i) => i.key === item.receiveMethod)?.value}</span>
            </div>

            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%', marginBottom: '2%' }}>Ngày tạo </span>
              <span style={{ flex: 1 }}>{dayjs(item?.createdAt).format(FORMAT_DATE)}</span>
            </div>

            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%', marginBottom: '2%' }}>Trạng thái </span>
              <span style={{ flex: 1 }}>{item?.isActivate ? 'Đang hoạt động' : 'Ngưng hoạt động'}</span>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
              <Button type="primary" onClick={() => navigate(PATH.items.list)}>
                Quay lại
              </Button>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};
